<template>
  <nuxt-link
    :data-album-id="item.id"
    exact-active-class="bg-shell-200 text-heroblue font-semibold"
    class="group leading-none text-charcoal no-underline hover:bg-shell-200 py-2 px-4 flex gap-2 items-center"
    :class="level > 1 ? ' border-l border-l-shell-600' : null"
    :to="useBuildRoute().toAlbum({album: item, userId, shareToken})"
    @click="toggleNestedAlbums(item.id, true, shareToken)"
  >

    <div class="relative flex">
      <button @click.stop.prevent="toggleNestedAlbums(item.id, false, shareToken)" class="flex-center absolute -left-[18px] h-full text-charcoal" v-if="item.albums_count && !disableNesting">
        <u-icon v-if="isLoadingNested" :name="COMMON_ICONS.loading" class="text-lg animate-spin" />
        <u-icon v-else-if="nestedAlbums.get(item.id)" name="i-ri-arrow-down-s-fill" class="text-xl" />
        <u-icon v-else name="i-ri-arrow-right-s-fill" class="text-xl" />
      </button>
      <album-avatar v-if="showCoverPhotos" :album="item" size="md" :ui="{size: {md: avatarSize}, icon: {size: {md: iconSize}}}" />
    </div>
    <span class="flex-1 flex flex-col min-w-0">
      <span class="text-sm leading-none break-long-string">{{item.name}}</span>
      <span class="flex gap-1 items-center font-normal">
        <span class="text-gray-500 text-2xs mt-1" v-if="item.albums_count">{{item.albums_count}} Nested</span>
      </span>
    </span>
    <span class="text-gray-400 text-2xs">{{item.files_count}}</span>
  </nuxt-link>

  <div v-if="nestedAlbums.get(item.id) && !disableNesting" class="pl-3" :class="level > 1 ? 'border-l border-l-shell-600' : ''">
    <album-navigation-item
      v-for="album in nestedAlbums.get(item.id)"
      :album-id="album.id"
      :share-token="shareToken"
      :key="album.id"
      :item="album"
      :level="level + 1"
      :show-cover-photos="showCoverPhotos"
    />
  </div>

</template>

<script setup>
  const props = defineProps({
    item: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 1
    },
    albumId: String,
    shareToken: String,
    disableNesting: Boolean,
    showCoverPhotos: {
      type: Boolean,
      default: true
    }
  });

  const {user_id_or_slug: userId} = useRoute().params;

  const avatarSize = computed(() => {
    switch (props.level) {
      case 1:
        return 'h-[38px] w-[38px]';
      case 2:
        return 'h-[34px] w-[34px]';
      default:
        return 'h-[30px] w-[30px]';
    }
  });

  const iconSize = computed(() => {
    switch (props.level) {
      case 1:
        return 'h-6 w-6';
      case 2:
        return 'h-5 w-5';
      default:
        return 'w-4 h-4';
    }
  });

  const albumsStore = useAlbumsStore();
  const {nestedAlbums} = storeToRefs(albumsStore);

  const isLoadingNested = ref(false);
  async function toggleNestedAlbums(parentAlbumId, stayOpen, shareToken) {
    if (props.disableNesting) {
      return;
    }

    isLoadingNested.value = true;

    try {
      if (nestedAlbums.value.get(parentAlbumId) && !stayOpen) {
        nestedAlbums.value.delete(parentAlbumId);
      } else if (!nestedAlbums.value.get(parentAlbumId)) {
        await albumsStore.getNestedAlbums({parentAlbumId, userId: props.item.user_id, shareToken});
      }
    } finally {
      isLoadingNested.value = false;
    }
  }
</script>
